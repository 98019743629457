import React from 'react';
import './App.css';
import pumpjackImage from './images/jack.jpg';

function App() {
  return (
    <div className="App">
      {/* Header */}
      <header className="header">
        <h1>Moonshot Consulting LLC</h1>
      </header>

      {/* Main Content */}
      <main className="main-content">
        <img src={pumpjackImage} alt="Oil Pumpjack" className="pumpjack-image" />

        <p>Consulting Services in the Oil & Gas Industry</p>

        {/* Informational Squares */}
        <div className="info-squares">
          <div className="square">
            <h2>Exploration Consulting</h2>
            <p>Expert guidance on oil and gas exploration projects.</p>
          </div>
          <div className="square">
            <h2>Mineral Rights Advisory</h2>
            <p>Let us help you navigate the complex world of mineral rights.</p>
          </div>
          <div className="square">
            <h2>Data Integration</h2>
            <p>Seamlessly integrate oil and gas production data into your systems.</p>
          </div>
        </div>

        {/* Contact Button */}
        <a href="mailto:sales<at>moonshotconsulting.io" className="contact-button">
          Contact Us
        </a>
      </main>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Moonshot Consulting LLC. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;